import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './index.css';
import { NavItems, NavItemsLabelMap } from '../nav-drawer/nav-drawer';
import { debounce } from '@mui/material';

export interface SubMenuItems {
  text: string;
  onClick: () => { success: boolean };
  icon?: JSX.Element;
  css_selector?: string;
}

interface SubMenuProps {
  menuId: NavItems;
  trigger: React.ReactNode;
  items: SubMenuItems[];
}

export const SubMenu: React.FC<SubMenuProps> = ({ menuId, trigger, items }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    debouncedhandleLeavingTrigger.clear();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const debouncedhandleClose = debounce(handleClose, 500);

  const handleLeavingTrigger = (e: any) => {
    const { relatedTarget, target } = e;
    if (!target?.id) {
      setOpen(false);
      return;
    }

    if (relatedTarget.id?.split('-')[0] === menuId) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const debouncedhandleLeavingTrigger = debounce(handleLeavingTrigger, 500);

  const onItemClick = (item: { text: string; onClick: () => void }) => {
    handleClose();
    item.onClick();
  };

  const showSubmenuTitle = (): boolean => {
    return !(menuId === NavItems.ME || menuId == NavItems.HOME);
  };

  return (
    <div className="sub-menu-wrapper" id={menuId + 'trigger2'}>
      <div
        id={menuId + 'trigger'}
        className="trigger-wrapper"
        style={{ zIndex: '5' }}
        onClick={handleClick}
        onMouseOver={handleClick}
        onMouseLeave={debouncedhandleLeavingTrigger}
      >
        {trigger}
      </div>

      <Menu
        onMouseLeave={debouncedhandleClose}
        id={`${menuId}-menu menu`}
        sx={{ zIndex: '1' }}
        anchorEl={document.getElementById(menuId + 'trigger')}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          // small margin left fixes dissapearing menu on desktop hover
          sx: { zIndex: '2', marginLeft: '-2px' },
          'aria-labelledby': `${menuId}-button`,
        }}
      >
        {showSubmenuTitle() && (
          <div
            key={`${menuId}-title`}
            id={`${menuId}-title`}
            className="submenu-title"
          >
            <span>{NavItemsLabelMap[menuId]}</span>
          </div>
        )}
        {items.map((item, index) => (
          <MenuItem
            className={item.css_selector ? `${item.css_selector}` : ''}
            key={index}
            id={`${menuId}-${index}`}
            onClick={() => onItemClick(item)}
          >
            <div
              className={
                item.css_selector
                  ? `submenu-text-wrapper ${item.css_selector}`
                  : 'submenu-text-wrapper'
              }
              id={`${menuId}-text-wrapper-${index}`}
            >
              <span>{item.text}</span>
              {item.icon && <div style={{ height: '100%' }}>{item.icon}</div>}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
