//This service manages checking if things have been saved to warn the user before navigating
//the system was built on localstorage
//the hope is that we can move things to this hook at some point and use a global variable

import { createContext, useContext, useState } from 'react';
import { NavigateOptions, useNavigate } from 'react-router';
import { useAuth } from '../api/auth';
//TODO check if needed
import { useDispatch } from 'react-redux';
import { ResetOnLogout } from '../reducers';

interface NavContextType {
  isChangesMade: boolean;
  setIsChangesMade: (value: boolean) => void;
  navigateTo: (
    url: string,
    forceNavigation?: boolean,
    isLogOut?: boolean,
    options?: NavigateOptions,
  ) => { success: boolean };
  cancelNavigation: () => void;
  confirmNavigation: () => void;
  showWarningDialog: boolean;
}

const NavContext = createContext<NavContextType | null>(null);

export const NavProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth: any = useAuth();
  const dispatch = useDispatch();
  const [isChangesMade, setIsChangesMade] = useState<boolean | undefined | any>(
    undefined,
  );
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const [url, setUrl] = useState('/');
  const navigate = useNavigate();

  //for all components to use instead of navigate from useNavigate()
  const navigateTo = (
    url: string,
    forceNavigation = false,
    isLogOut = false,
    options: NavigateOptions = {},
  ): { success: boolean } => {
    // used because useState is async
    if (forceNavigation) {
      setShowWarningDialog(false);
      setTimeout(() => {
        setShowWarningDialog(false);
      }, 100);
      setIsChangesMade(false);
      if (isLogOut) {
        logout();
      } else {
        navigate(url, options);
      }
      setUrl('/');
      return { success: true };
    }

    if (isChangesMade) {
      setUrl(url);
      setShowWarningDialog(true);
      setLogOut(isLogOut);
      return { success: false };
    } else {
      setShowWarningDialog(false);
      setTimeout(() => {
        setShowWarningDialog(false);
      }, 100);
      if (isLogOut) {
        logout();
      } else {
        //want to MAKE it navigate even if it is alreayd on that page like to make it re-render by force
        navigate(url, options);
      }
      setUrl('/');
      return { success: true };
    }
  };

  const cancelNavigation = () => {
    setShowWarningDialog(false);
    setUrl('/');
  };

  const confirmNavigation = () => {
    setShowWarningDialog(false);
    setIsChangesMade(false);
    navigateTo(url, true, logOut);
  };

  const logout = () => {
    dispatch(ResetOnLogout());
    auth.logout();
  };

  return (
    <NavContext.Provider
      value={{
        isChangesMade,
        setIsChangesMade,
        navigateTo,
        cancelNavigation,
        confirmNavigation,
        showWarningDialog,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const useNav = () => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error('useNav must be used within a NavProvider');
  }
  return context;
};
