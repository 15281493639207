import { Outlet } from 'react-router-dom';
import NavDrawer from './nav-drawer';
import {  useState } from 'react';
import { WarningDialog } from '../../warningdialog';
import { useNav } from '../../../hooks/use.nav.hook';

const Layout: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { confirmNavigation, cancelNavigation, showWarningDialog } = useNav();

  return (
    <>
      <NavDrawer setDrawerOpen={setDrawerOpen} overlayActive={showWarningDialog}/>
      <main
        style={{
          marginLeft: drawerOpen ? 'calc(230px + 1rem)' : 'calc(56px + 1rem)',
          padding: '1rem',
          transition: 'margin-left 0.3s ease',
        }}
      >
        <Outlet />
      </main>
      <WarningDialog
        showWarningDialog={showWarningDialog}
        handleOkButton={() => confirmNavigation()}
        handleCloseButton={() => cancelNavigation()}
      />
    </>
  );
};

export default Layout;
