import { ApiResponse } from '../../types/api';
import {
  Activity,
  CreateUsersDTO,
  ExtendedUsersModel,
  UserDetail,
  UsersModel,
} from '../../types/user';
import axiosInstance from '../axios';

class UsersService {
  getUsers(): Promise<ApiResponse<UsersModel[]>> {
    return axiosInstance.get(`/api/users`);
  }

  /*
   *Returns all managers OR if userIds are passed back, returns managers associated with those employee id's
   */
  getManagers(userIds?: string[]): Promise<ApiResponse<UsersModel[]>> {
    const params = userIds?.length ? { userIds: userIds.join(',') } : {};
    return axiosInstance.get(`/api/users/managers`, { params });
  }

  //TODO this returns the exact same as above?
  getUsersWithoutPermission(): Promise<ApiResponse<UsersModel[]>> {
    return axiosInstance.get(`/api/users/get/getUsersWithoutPermission`);
  }

  //TODO : Backend should return users model here instead.
  getUserById(id: string): Promise<ApiResponse<UserDetail>> {
    return axiosInstance.get(`/api/users/${id}`);
  }

  getUsersForAdmin(): Promise<ApiResponse<ExtendedUsersModel[]>> {
    return axiosInstance.get(`/api/users/get/getUsersForAdminExport`);
  }

  getPermissionCategories() {
    return axiosInstance.get(`    /api/permissioncategory`);
  }
  /*
   *For creating multiple users via the bulk upload excel file
   */
  createUsers(body: CreateUsersDTO): Promise<ApiResponse<any[]>> {
    return axiosInstance.post(`/api/users/create/createUsers`, body);
  }

  createUser(body: any): Promise<ApiResponse<any>> {
    return axiosInstance.post('/api/users', body);
  }

  updateUser(body: any, id: string | number) {
    return axiosInstance.put(`/api/users/${id}`, body);
  }

  //TODO update return type
  archiveUsers(body: { users: UsersModel[] }): Promise<ApiResponse<any[]>> {
    return axiosInstance.put(`/api/users/archive/archiveusers`, body);
  }

  //TODO update return type
  reinstateUsers(body: { users: UsersModel[] }): Promise<ApiResponse<any[]>> {
    return axiosInstance.put(`/api/users/archive/reinstateusers`, body);
  }

  getHistoryByUser(id: string): Promise<ApiResponse<Activity[]>> {
    return axiosInstance.get(`/api/userindividualskilledithistory/user/${id}`);
  }

  //TODO update return type
  getGoalsForUser(user_id: string): Promise<ApiResponse<any[]>> {
    return axiosInstance.get(`/api/userskillgoals/user/${user_id}`);
  }

  createGoal(body: any) {
    return axiosInstance.post(`/api/userskillgoals`, body);
  }

  fetchFieldsForUsers(
    field: string,
    userIds?: string[],
  ): Promise<ApiResponse<string[]>> {
    const params: any = { field };
    if (userIds?.length) {
      params.userIds = userIds.join(',');
    }
    return axiosInstance.get(`/api/users/uniquefields`, { params });
  }

  getIsUserManager(
    userId: string | number,
  ): Promise<ApiResponse<{ is_manager: boolean }>> {
    return axiosInstance.get(`/api/users/isManager/${userId}`);
  }
}

export const usersService = new UsersService();
