import { ApiResponse, Pagination } from './api';
import { JobsModel } from './job';
import { UsersModel } from './user';

export enum JobApplicatnRelTypes {
  SURVEY_ONLY = 'survey',
  CURRENT_PLAN = 'current_plan',
  PAST_PLAN = 'past_plan',
  TARGET_PLAN = 'target_plan',
}

export interface UpdateRelTypeDto {
  user_id: number;
  plan_id: number;
  rel_type: JobApplicatnRelTypes;
}

export interface JobApplicantModel {
  id: number;
  org_id: number;
  job_id: number;
  user_id: number;
  resume: null | string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  added_by: number;
  has_invited: 'Y' | 'N';
  last_surveyed: null | string;
  manager_last_surveyed: Date;
  survey_manager: boolean;
  rel_type: JobApplicatnRelTypes;
}

export interface JobApplicantForUser extends JobApplicantModel {
  survey_complete: boolean;
  manager_survey_complete: boolean;
  job: JobsModel;
  user: UsersModel;
}

export interface GetPlansByRelTypeResponse extends JobsModel {}

export interface GetUserPlansDto extends Pagination {
  user_id: number;
  rel_type?: JobApplicatnRelTypes;
}

export enum JobApplicantPermissions {
  EDIT_MY_PLAN_TYPE = 'edit_my_plan_type',
  EDIT_ALL_PLAN_TYPE = 'edit_all_plan_type',
  EDIT_MY_REPORTS_PLAN_TYPE = 'edit_my_reports_plan_type',
}
