import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Logo from '../../../../assets/nav-icons/vwf-logo.png';
import LogoWithoutText from '../../../../assets/nav-icons/vwf-logo-without-text.png';
import PeopleIcon from '../../../../assets/nav-icons/people.svg';
import SkillsIcon from '../../../../assets/nav-icons/skills.svg';
import VisualizationsIcon from '../../../../assets/nav-icons/visualizations.svg';
import PlansIcon from '../../../../assets/nav-icons/plans.svg';
import AdminIcon from '../../../../assets/nav-icons/admin.svg';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  faCircleQuestion,
  faHome,
  faChevronRight,
  faL,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../api/auth';
import SubMenu from '../sub-menu';
import { skillsService } from '../../../../api/services/skills.service';
import { useNav } from '../../../../hooks/use.nav.hook';
import { SubMenuItems } from '../sub-menu/sub-menu';
import {
  createUserPermissionsMap,
  hasPermission,
} from '../../../../utils/helperfunctions';
import { ca } from 'date-fns/locale';
import { capitalize } from '@mui/material';
import { changeLanguage } from 'i18next';
import { plansService } from '../../../../api/services/plans.service';
import { add } from 'date-fns';

const drawerWidth = 240;
export enum NavItems {
  PEOPLE = 'people',
  SKILLS = 'skills',
  ADMIN = 'admin',
  PLANS = 'plans',
  VISUALIZATIONS = 'visualizations',
  HOME = '',
  ME = 'me',
}
export const NavItemsLabelMap: Record<NavItems, string> = {
  [NavItems.PEOPLE]: 'People',
  [NavItems.SKILLS]: 'Skills',
  [NavItems.ADMIN]: 'Admin',
  [NavItems.PLANS]: 'Plans',
  [NavItems.VISUALIZATIONS]: 'Visualizations',
  [NavItems.HOME]: 'My Dashboard',
  [NavItems.ME]: 'My Account',
};
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  overflowX: 'visible',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));

const NavDrawer: React.FC<{
  setDrawerOpen: (open: boolean) => void;
  overlayActive: boolean;
}> = ({ setDrawerOpen, overlayActive }) => {
  const [t] = useTranslation();
  const auth = useAuth();
  const currentUser = auth.getUser();
  const { navigateTo, isChangesMade } = useNav();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(NavItems.HOME);

  const [peopleItems, setPeopleItems] = useState<SubMenuItems[]>([]);
  const [planItems, setPlanItems] = useState<SubMenuItems[]>([]);
  const [skillItems, setSkillItems] = useState<SubMenuItems[]>([]);
  const [visualizationItems, setVisualizationItems] = useState<SubMenuItems[]>(
    [],
  );
  const [adminItems, setAdminItems] = useState<SubMenuItems[]>([]);

  //Items all users have no matter what permissions they have
  const BASE_ME_ITEMS = [
    {
      text: `${t('nav.me.security')}`,
      onClick: () => {
        return navigateTo('/resetpassword');
      },
    },
    {
      text: `${t('nav.me.help')}`,
      onClick: () => {
        try {
          // @ts-ignore=
          window.StonlyWidget('openKB', { teamKnowledgeBaseId: '31158' });
        } catch (err) {
          console.log('stonly error', err);
        }

        return { success: true };
      },
      css_selector: 'nav-link-need-assistance',
      icon: <FontAwesomeIcon icon={faCircleQuestion} />,
    },
    {
      text: `${t('nav.me.contact_support')}`,
      onClick: () => {
        window.open(
          'http://www.visualworkforce.com/user-documentation',
          '_blank',
        );
        return { success: true };
      },
    },
    {
      text: `${t('nav.me.about')}`,
      onClick: () => {
        return navigateTo('/about');
      },
    },
    {
      text: `${t('nav.me.logout')}`,
      onClick: () => {
        return navigateTo('/', false, true);
      },
    },
  ];
  const [meItems, setMeItems] = useState<SubMenuItems[]>(BASE_ME_ITEMS);

  const navItemsConfig = useMemo(
    () => [
      {
        menuId: NavItems.PEOPLE,
        icon: PeopleIcon,
        labelKey: 'nav.people',
        items: peopleItems,
      },
      {
        menuId: NavItems.PLANS,
        icon: PlansIcon,
        labelKey: 'nav.plans',
        items: planItems,
      },
      {
        menuId: NavItems.SKILLS,
        icon: SkillsIcon,
        labelKey: 'nav.skills',
        items: skillItems,
      },
      {
        menuId: NavItems.VISUALIZATIONS,
        icon: VisualizationsIcon,
        labelKey: 'nav.visualizations',
        items: visualizationItems,
      },
      {
        menuId: NavItems.ADMIN,
        icon: AdminIcon,
        labelKey: 'nav.admin',
        items: adminItems,
      },
    ],
    [peopleItems, planItems, skillItems, visualizationItems, adminItems],
  );

  //ui feedback for current location
  useEffect(() => {
    setActiveNav(location.pathname);
  }, [location]);

  //update on user chanve
  useEffect(() => {
    (async () => {
      const categories = await fetchSkillCategories();
      const plansIManage = await fetchPlans();

      setPermissionsAndMenuItems(categories, plansIManage);
    })();

    //isChangesMade added because nav buttons were using stale context/not prompting warning dialog
  }, [currentUser, isChangesMade]);

  const setPermissionsAndMenuItems = (
    skillCategoryItems?: SubMenuItems[],
    plansIManage: string[] = ['undefined'],
  ) => {
    if (!plansIManage.length) {
      plansIManage = ['undefined'];
    }
    if (!currentUser || !currentUser.permissions) {
      clearAllSubmenus();
    }

    const permissionsMap = createUserPermissionsMap(currentUser.permissions);
    //people navigation
    const peopleNavItems = createNavItems(permissionsMap, [
      {
        permission: [
          'view_all_user',
          'view_own_user',
          'view_my_plan_participants',
        ],
        textKey: 'nav.view.all',
        url: '/people',
        addSSItems: [
          {
            key: 'people-list',
            value: JSON.stringify({ archived: { values: ['N'] } }),
          },
        ],
        removeSSItems: [
          '/peoplemanagerproficienciesneedcomplete',
          'people-skill-search',

          '/peoplemanager',
          '/peopleplanimanage',
          '/peoplesurveycomplete',
        ],
      },
      {
        permission: ['view_dashboard_navigation', 'view_own_user'],
        textKey: 'nav.people.profile',
        url: `/person/${currentUser.id}`,
        addSSItems: [],
        removeSSItems: [
          '/peoplemanagerproficienciesneedcomplete',
          'people-skill-search',
          'people-list',
          '/peoplemanager',
          '/peopleplanimanage',
          '/peoplesurveycomplete',
        ],
      },
      {
        permission: ['view_dashboard_navigation', 'view_all_user'],
        textKey: 'nav.people.report.to.me',
        url: `/people`,
        //these items in session storage trigger hook to sort through the data. need to move to BE
        addSSItems: [
          {
            key: '/peoplemanager',
            value: JSON.stringify({ manager: `${currentUser.id}` }),
          },
        ],
        removeSSItems: [
          'people-skill-search',
          'people-list',
          '/peopleplanimanage',
          '/peoplesurveycomplete',
          '/peoplemanagerproficienciesneedcomplete',
        ],
      },
      // {
      //   //TODO ask about this permission
      //   permission: [
      //     'view_dashboard_navigation',
      //     'view_all_user',
      //     'view_my_plan_participants',
      //   ],
      //   textKey: 'nav.people.plans.i.manage',
      //   url: '/people',
      //   addSSItems: [
      //     {
      //       key: '/peopleplanimanage',
      //       value: JSON.stringify({
      //         all: false,
      //         values: plansIManage,
      //       }),
      //     },
      //   ],
      //   removeSSItems: [
      //     'people-skill-search',
      //     'people-list',
      //     '/peoplemanager',
      //     '/peoplesurveycomplete',
      //     '/peoplemanagerproficienciesneedcomplete',
      //   ],
      // },
      {
        permission: ['view_dashboard_navigation', 'view_all_user'],
        textKey: 'nav.people.open.surveys',
        url: '/people',
        addSSItems: [
          {
            key: '/peoplesurveycomplete',
            value: 'true',
          },
        ],
        removeSSItems: [
          'people-skill-search',
          'people-list',
          '/peoplemanager',
          '/peopleplanimanage',
          '/peoplemanagerproficienciesneedcomplete',
        ],
      },
      //TODO ask how profile is different from other my profile

      {
        permission: ['view_dashboard_navigation', 'add_user'],
        textKey: 'nav.people.new',
        url: '/person',
        addSSItems: [],
        removeSSItems: [],
      },
    ]);

    //plans navigation
    const planNavItems = createNavItems(permissionsMap, [
      {
        permission: ['view_all_plan'],
        textKey: 'nav.view.all',
        url: '/plans',
        addSSItems: [],
        removeSSItems: [
          '/planplanimange',
          '/peoplemanagerproficienciesneedcomplete',
          'plan-list',
          'people-list',
          'skill-list',
        ],
      },
      {
        permission: ['view_dashboard_navigation', 'view_all_plan'],
        textKey: 'nav.plans.mine',
        url: '/plans',
        addSSItems: [
          {
            key: '/planplanimange',
            value: `${currentUser.id}`,
          },
        ],
        removeSSItems: ['plan-list', '/peoplemanagerproficienciesneedcomplete'],
      },
      {
        permission: [
          'view_dashboard_navigation',
          'view_all_user',
          'edit_manager_proficiency',
          'edit_manager_proficiency_for_people_who_report_to_me',
        ],
        textKey: 'nav.plans.manager.surveys',
        url: '/people',
        addSSItems: [
          {
            key: '/peoplemanagerproficienciesneedcomplete',
            value: JSON.stringify({
              manager: `${currentUser.id}`,
              nomanagerproficiency: 'true',
            }),
          },
        ],
        removeSSItems: [
          'plan-list',
          'people-skill-search',
          'people-list',
          '/peoplemanager',
          '/peopleplanimanage',
          '/peoplesurveycomplete',
        ],
      },
      {
        permission: ['view_dashboard_navigation', 'add_plan'],
        textKey: 'nav.plans.new',
        url: '/plan',
        addSSItems: [],
        removeSSItems: [
          'plan-list',
          'people-skill-search',
          'people-list',
          '/peoplemanager',
          '/peopleplanimanage',
          '/peoplesurveycomplete',
        ],
      },
    ]);

    //skills navigation
    let skillNavItems = createNavItems(permissionsMap, [
      {
        permission: ['view_skill'],
        textKey: 'nav.view.all',
        url: '/skills',
        addSSItems: [],
        removeSSItems: ['/skillcategory'],
      },
      {
        permission: ['view_dashboard_navigation', 'add_skill'],
        textKey: 'nav.skills.new',
        url: '/skill',
        addSSItems: [],
        removeSSItems: ['people-list', 'skill-list', 'plans-list'],
      },
    ]);

    if (skillCategoryItems?.length && permissionsMap['view_skill']) {
      skillNavItems.splice(1, 0, ...skillCategoryItems);
    }

    //visualization navigation
    const visualizationNavItems = createNavItems(permissionsMap, [
      {
        permission: ['view_visualization'],
        textKey: 'nav.view.all',
        url: '/visualizations',
      },
      {
        permission: ['view_dashboard_navigation', 'view_visualization'],
        textKey: 'nav.im',
        url: '/internalMobilityVisualizations',
      },
      {
        permission: ['view_dashboard_navigation', 'view_visualization'],
        textKey: 'nav.certs',
        url: '/certsandaptitudes',
      },
      {
        permission: ['view_dashboard_navigation', 'view_visualization'],
        textKey: 'nav.skill.concentration',
        url: '/skillconcentration',
      },
    ]);

    //visualization navigation
    const adminNavItems = createNavItems(permissionsMap, [
      //TODO check this is correct. ~ lines 170 in old hearder ts file
      {
        permission: ['company_settings'],
        textKey: 'nav.admin.dashboard',
        url: '/admin',
      },
      {
        permission: ['view_role'],
        textKey: 'nav.admin.roles',
        url: '/admin?tab=security-roles',
      },
      {
        permission: ['manage_external_integrations'],
        textKey: 'nav.admin.integrations',
        url: '/admin?tab=integrations',
      },
    ]);

    //ME navigation
    let additionalMeNavItems = createNavItems(permissionsMap, [
      {
        permission: ['view_dashboard_navigation', 'view_own_user'],
        textKey: 'nav.people.profile',
        url: `/person/${currentUser.id}`,
      },
      {
        permission: ['company_settings'],
        textKey: 'nav.admin.dashboard',
        url: '/admin',
      },
    ]);

    setMeItems(additionalMeNavItems.concat(BASE_ME_ITEMS));
    setPeopleItems(peopleNavItems);
    setPlanItems(planNavItems);
    setSkillItems(skillNavItems);
    setVisualizationItems(visualizationNavItems);
    setAdminItems(adminNavItems);
  };

  const createNavItems = (
    permissionsMap: Record<string, boolean>,
    //these session storage items should be query items sent to BE to update search. currently being handled by hook (S)
    //useFilterWithSessionStorage
    items: {
      permission: string[];
      textKey: string;
      url: string;
      removeSSItems?: string[] | 'all';
      addSSItems?: { key: string; value: string }[];
    }[],
  ) => {
    return items
      .filter((item) => hasPermission(item.permission, permissionsMap))
      .map((item) => ({
        text: t(item.textKey),
        onClick: () => {
          //TODO remove this/clean up when moved to be
          if (item.addSSItems && item.removeSSItems) {
            for (let pair of item.addSSItems) {
              sessionStorage.setItem(pair.key, pair.value);
            }
            for (let key of item.removeSSItems) {
              sessionStorage.removeItem(key);
            }
          }
          // return navigateTo(item.url)
          return navigateTo(item.url, false, false, {
            state: { forceRender: Date.now() },
          });
        },
      }));
  };

  const fetchSkillCategories = async () => {
    try {
      const res = await skillsService.fetchSkillCategories();
      const categories: SubMenuItems[] = [];
      for (let i = 0; i < res.data.length; i++) {
        let category = res.data[i];
        categories.push({
          text: capitalize(category.name),
          onClick: () => {
            sessionStorage.setItem(
              `skill-list`,
              JSON.stringify({ q: category.name }),
            );
            sessionStorage.setItem(`/skillcategory`, category.name);
            return navigateTo(`/skills`, false, false, {
              state: { forceRender: Date.now() },
            });
          },
        });
      }
      return categories;
    } catch (error) {
      console.log('Error fetching skills:', error);
      return [];
    }
  };

  const fetchPlans = async (): Promise<string[]> => {
    try {
      const res = await plansService.getPlans();

      let plans = res.data
        .filter(
          (p: any) => p.created_by == currentUser.id && p.fl_archive == 'N',
        )
        .map((p: any) => p.id);
      return plans as string[];
    } catch (error) {
      console.log('Error fetching plans:', error);
      return [];
    }
  };

  const clearAllSubmenus = () => {
    setPeopleItems([]);
    setSkillItems([]);
    setPlanItems([]);
    setVisualizationItems([]);
    setMeItems(BASE_ME_ITEMS);
    setAdminItems([]);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setDrawerOpen(open);
  }, [open]);

  const navMap: Record<string, NavItems> = {
    people: NavItems.PEOPLE,
    person: NavItems.PEOPLE,
    admin: NavItems.ADMIN,
    plan: NavItems.PLANS,
    plans: NavItems.PLANS,
    skill: NavItems.SKILLS,
    skills: NavItems.SKILLS,
    visualizations: NavItems.VISUALIZATIONS,
    vizop: NavItems.VISUALIZATIONS,
    compareplans: NavItems.VISUALIZATIONS,
    planproficency: NavItems.VISUALIZATIONS,
    skillmatrix: NavItems.VISUALIZATIONS,
    skillprogression: NavItems.VISUALIZATIONS,
    skillconcentration: NavItems.VISUALIZATIONS,
    comparepeopleonplan: NavItems.VISUALIZATIONS,
    internalMobilityVisualizations: NavItems.VISUALIZATIONS,
    certsandaptitudes: NavItems.VISUALIZATIONS,
  };

  const setActiveNav = (url: string) => {
    //TODO handle the case where the user's 'dashboard' is their profile
    const matchedKey = Object.keys(navMap).find((key) => url.includes(key));
    matchedKey ? setSelected(navMap[matchedKey]) : setSelected(NavItems.HOME);
  };

  const isSelected = (navItem: NavItems): boolean => {
    return selected == navItem;
  };

  const handleHomeNavigation = () => {
    if (currentUser.permissions.includes('view_dashboard_navigation')) {
      navigateTo('/');
    } else if (currentUser.permissions.includes('view_own_user')) {
      navigateTo(`/person/${currentUser.id}`);
    } else navigateTo('/404');
  };

  return (
    <Box
      className={`nav-drawer-container ${overlayActive ? ' overlay' : null}`}
    >
      <Drawer variant="permanent" open={open} className="nav-drawer">
        <IconButton onClick={toggleDrawer} className="logo-icon-button">
          <img
            src={open ? Logo : LogoWithoutText}
            alt="Logo"
            className={open ? 'nav-bar-logo logo-open' : 'nav-bar-logo'}
          />
        </IconButton>

        <Box className="top-icons">
          <IconButton
            onClick={() => handleHomeNavigation()}
            className={
              isSelected(NavItems.HOME) ? 'icon-button selected' : 'icon-button'
            }
          >
            <FontAwesomeIcon className=" home-icon" icon={faHome} />
            {open && <span className="icon-label">{t('nav.dashboard')}</span>}
          </IconButton>

          {navItemsConfig
            .filter((navItem) => navItem.items.length > 0)
            .map(({ menuId, icon, labelKey, items }, index) => (
              <SubMenu
                key={index}
                menuId={menuId}
                trigger={
                  <IconButton
                    id={`${menuId}-icon`}
                    className={
                      isSelected(menuId)
                        ? 'icon-button selected'
                        : 'icon-button'
                    }
                  >
                    <img
                      src={icon}
                      alt={t(labelKey)}
                      className="nav-bar-icon"
                    />
                    <div className="flex-row-between">
                      {open && (
                        <span className="icon-label">{t(labelKey)}</span>
                      )}
                      {open && items?.length > 0 && (
                        <FontAwesomeIcon
                          className="chevron-right"
                          icon={faChevronRight}
                        />
                      )}
                    </div>
                  </IconButton>
                }
                items={items}
              />
            ))}
        </Box>

        <Box className="bottom-section">
          <SubMenu
            menuId={NavItems.ME}
            trigger={
              <IconButton
                className="user-icon-wrapper"
                id={`${NavItems.ME}-icon`}
              >
                <div className="green-circle">
                  {currentUser.fname[0] + currentUser.lname[0]}
                </div>

                {open && (
                  <div className="flex-row-between">
                    <div className="current-user-container">
                      <span>{`${currentUser.fname}  ${currentUser.lname}`}</span>
                      <span className="font-regular">
                        {currentUser.orgName}
                      </span>
                    </div>
                    <div className="h-full flex flex-row items-center">
                      <FontAwesomeIcon
                        className="chevron-right chevron-white"
                        icon={faChevronRight}
                      />
                    </div>
                  </div>
                )}
              </IconButton>
            }
            items={meItems}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default NavDrawer;
