import { SkillWithProficiencies } from '../types/skills';
import { CurrentUser, UsersModel } from '../types/user';
import {
  BLACK,
  BRAND_GREEN,
  BRAND_RED,
  BRAND_YELLOW,
  DARK_GRAY,
  MEDIUM_GRAY,
} from './constants';

export const formatDate = (date?: Date | string) => {
  if (!date) return;

  return new Date(date).toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
};

export const sortByPriority = (items: any[]): any[] => {
  items.sort((a, b) => {
    if (a.priority === null && b.priority === null) return 0;
    if (a.priority === null) return -1;
    if (b.priority === null) return 1;
    return a.priority - b.priority;
  });
  return items;
};

/**
 * will correctly capitalize one name or both if given two names.
 */
export const formatName = (first: string, last?: string): string => {
  if (!first) return '';
  const firstName =
    first.charAt(0).toUpperCase() + first.slice(1).toLowerCase();
  if (!last) return firstName;
  const lastName = last.charAt(0).toUpperCase() + last.slice(1).toLowerCase();
  return firstName + ' ' + lastName;
};

export const sortUsersAlphabetically = (users: UsersModel[]) => {
  return users.sort((a, b) => {
    const fullnameA = a.fname + ' ' + a.lname;
    const fullnameB = b.fname + ' ' + b.lname;
    return fullnameA.localeCompare(fullnameB);
  });
};

export function onlyUnique(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}

export function getUniqueArray(array: any[]) {
  return array.filter((value, index, array) => onlyUnique(value, index, array));
}
//labels for slider
export const prepareSliderData = (smeDefinitions: any[], t: Function) => {
  const maxSmeDefinition = smeDefinitions.reduce(
    (prev, current) => (prev.sme_score > current.sme_score ? prev : current),
    { sme_score: 0 },
  );

  const minValue = -1;
  const maxValue = maxSmeDefinition.sme_score;
  const tickValues =
    smeDefinitions.length === 0
      ? Array.from({ length: 11 }, (_, i) => ({ value: i, label: i }))
      : smeDefinitions.map((sme) => ({
          value: sme.sme_score,
          label: sme.sme_score,
          definition: sme.sme_definition,
        }));

  tickValues.unshift({
    value: -1,
    label: t('common.na'),
    definition: t('common.not.applicable'),
  });

  return { minValue, maxValue, tickValues };
};

export function valueLabel(
  value: number,
  tickValues: { value: number; label: string | number; definition?: string }[],
) {
  return tickValues.map((tick: any) => {
    if (tick.value == value) {
      return `${value}: ${tick.definition || tick.label}`;
    }
  });
}

export const getProficiencyColorFromScore = (
  score: number,
  desiredScore: number,
  meetsTarget: number,
  partiallyMeetsTarget: number,
): string => {
  const meetsTargetValue = (meetsTarget / 100) * desiredScore;
  const partiallyMeetsTargetValue = (partiallyMeetsTarget / 100) * desiredScore;
  if (score >= meetsTargetValue) {
    return BRAND_GREEN;
  } else if (score >= partiallyMeetsTargetValue) {
    return BRAND_YELLOW;
  } else {
    return BRAND_RED;
  }
};

export const getDesiredContractColor = (scoreColor: string): string => {
  if (scoreColor == BRAND_GREEN) {
    return MEDIUM_GRAY;
  } else {
    return DARK_GRAY;
  }
};

export const getProficiencyColorFromSkill = (
  skill: SkillWithProficiencies,
  asManager: boolean = true,
  desiredValue: number,
  meetsTarget: number,
  partiallyMeetsTarget: number,
) => {
  let proficiency = asManager
    ? skill.manager_proficiency[0]
    : skill.user_proficiency[0];
  if (!proficiency) return BRAND_RED;

  return getProficiencyColorFromScore(
    proficiency.sme_score,
    desiredValue,
    meetsTarget,
    partiallyMeetsTarget,
  );
};

export const canEditManagerProficiency = (
  managerId: string,
  currentUser: CurrentUser,
): boolean => {
  if (currentUser.permissions.includes('edit_manager_proficiency')) return true;
  if (managerId === currentUser.id.toString()) {
    if (
      currentUser.permissions.includes(
        'edit_manager_proficiency_for_people_who_report_to_me',
      )
    )
      return true;
  }
  return false;
};

export const canViewManagerProficiency = (
  managerId: string,
  currentUser: CurrentUser,
): boolean => {
  if (currentUser.permissions.includes('view_manager_proficiency')) return true;
  if (managerId === currentUser.id.toString()) {
    if (
      currentUser.permissions.includes(
        'view_manager_proficiency_for_people_who_report_to_me',
      )
    )
      return true;
  }
  return false;
};
//create map for faster lookup if dealing with lots of permissions checks
export const createUserPermissionsMap = (
  permissions: string[],
): Record<string, boolean> => {
  return permissions.reduce((map, perm) => {
    map[perm] = true;
    return map;
  }, {} as Record<string, boolean>);
};

//check if a user's permissions'm map includes certain/all permissions in an array
export const hasPermission = (
  required: string | string[],
  permissionMap: Record<string, boolean>,
): boolean => {
  if (Array.isArray(required)) {
    return required.every((perm) => permissionMap[perm]);
  }
  return permissionMap[required] || false;
};
